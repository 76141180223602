<template>
	<main>
            <section class="mv">
                <h1>デイケア・ナイトケア</h1>
            </section>
            
            <section class="columns_2">
                <!-- <figure>
                    <img src="../../assets/ph_daycare.png" alt="">
                </figure> -->
                <div>
                    <h3>デイケアとは</h3>
                    <p>デイケアとは、こころの病を抱えながら地域で生活している方々が、さまざまな活動を通して、より充実した生活を送れるようにすることを目的として開設されています。</p>
                    <!-- <h3>利用する方法は？</h3>
                    <p>当クリニックの「外来」でのご相談や、他のクリニックからの紹介になります。通院しながらのご利用となります。</p>
                    <p>障がいと上手につきあって、地域でより充実した生活を送るための活動を行っています。そのために色々な職種のスタッフで支援しています。<br>
                    〈運動、音楽、アート、SST、調理など〉</p> -->
                    <h3>どんな人が参加しているの？</h3>
                    <ul>
                        <li>人と話すのは苦手。でも友達がほしい…</li>
                        <li>自宅に閉じこもりがちだけど、自宅以外にも居場所が欲しい…</li>
                        <li>生活リズムを改善したい、整えたい</li>
                        <li>就職の準備がしたい</li>
                        <li>作業所に通っているけれども、自信がない…</li>
                    </ul>
                    <p>など、このような皆さんが安心して過ごせる場所です。すがのクリニックでは、みなさんの目的に合わせたグループと様々なプログラムを用意しています。</p>
                </div>
            </section>

            <!-- デイケア紹介 -->
            <section class="daycare">
                <!-- タブ切り替え -->
                <ul class="daycare__nav">
                    <li class="daycare__nav--group view">グループに参加<!--<span>希望の日時を選びましょう</span>--></li>
                    <li class="daycare__nav--program">プログラム紹介<!--<span>実施プログラムはグループごとに決めます</span>--></li>
                </ul>
                <section class="daycareContents">
                    <!-- グループ -->
                    <section class="daycareContents__group view">
                        <div class="daycareContents__group__leading">
                            <!-- <p>時間・場所・目的に応じて６つのグループがあります。グループごとにプログラムに取り組みます。</p> -->
                            <!-- <h3>ご都合・ご希望に合う日時を選びましょう。</h3> -->
                            <h3>６つのグループがあります。</h3>
                        </div>
                        <ul class="daycareContents__group__nav">
                            <li class="daycareContents__group__nav--0 view">平日<strong>「お昼」</strong><span>（月火水金）</span><span>9:30〜15:30</span></li>
                            <li class="daycareContents__group__nav--1">平日<strong>「夜」</strong><span>（火金）</span><span>16:00〜20:00</span></li>
                            <li class="daycareContents__group__nav--2">週末<span>（土）</span><span>9:30〜15:30</span></li>
                        </ul>
                        <section class="daycareContents__group__contents">
                            <section class="daycareContents__group__contents__date--0 view">
                                <h4>この時間帯に開催されているグループ</h4>
                                <div class="daycareContents__group__cst">
                                    <div class="daycareContents__group__cst__title">
                                        <h5 class="daycareContents__group__cst__title--kagayaki">
                                            かがやき<span>グループ</span>
                                            <!-- <span>場所：デイケアルーム</span> -->
                                        </h5>
                                    </div>
                                    <div>
                                        <p>「社会復帰の準備をしていくこと」を目的としたグループです。グループ内で、当番や役割分担を行いながら、少しずつ自信をつけていきます。</p>
                                    </div>
                                </div>
                                <div class="daycareContents__group__cst">
                                    <div class="daycareContents__group__cst__title">
                                        <h5 class="daycareContents__group__cst__title--hikari">
                                            ひかり<span>グループ</span>
                                            <!-- <span>場所：ちゃお室</span> -->
                                        </h5>
                                    </div>
                                    <div class="daycareContents__group__cst__txt">
                                        <p>日中の居場所として、「今の機能を維持すること」を目的としたグループです。アットホームな雰囲気で、畳のお部屋が集合場所です。</p>
                                    </div>
                                </div>
                                <div class="daycareContents__group__cst">
                                    <div class="daycareContents__group__cst__title">
                                        <h5 class="daycareContents__group__cst__title--nanairo">
                                            なないろ<span>グループ</span>
                                            <!-- <span>場所：病棟</span> -->
                                        </h5>
                                    </div>
                                    <div>
                                        <p>お家とは違う場所で、「集団に慣れること」を目的としたグループです。まずは、少しの時間でもくる練習をしたり、人と話すことに慣れるところからのスタートもできます。</p>
                                    </div>
                                </div>
                            </section>
                            <section class="daycareContents__group__contents__date--1">
                                <h4>この時間帯に開催されているグループ</h4>
                                <div class="daycareContents__group__cst">
                                    <div class="daycareContents__group__cst__title">
                                        <h5 class="daycareContents__group__cst__title--night">
                                            ナイトケア<span>グループ</span>
                                            <!-- <span>場所：デイケアルーム</span> -->
                                        </h5>
                                    </div>
                                    <div>
                                        <p>仕事や学校帰りにホッとしたいなど、憩いの場を目的としたグループです。普段はプログラムが決まっておらず、落ち着いた雰囲気で自由に過ごしていますが、年に何回かイベントも企画しています。</p>
                                    </div>
                                </div>
                            </section>
                            <section class="daycareContents__group__contents__date--2">
                                <h4>この時間帯に開催されているグループ</h4>
                                <div class="daycareContents__group__cst">
                                    <div class="daycareContents__group__cst__title">
                                        <h5 class="daycareContents__group__cst__title--friend">
                                            ふれんど<span>グループ</span>
                                            <!-- <span>場所：デイケアルーム</span> -->
                                        </h5>
                                        <h5 class="daycareContents__group__cst__title--ron">
                                            Ron<span>グループ</span>
                                            <!-- <span>場所：病棟</span> -->
                                        </h5>
                                    </div>
                                    <div>
                                        <p>週末の余暇活動を目的としたグループです。スポーツ、室内ゲーム、会食会、旅行、季節の行事などを自分たちで企画して、楽しい時間を過ごしています。<br>「ふれんど」「Ron」と２つのグループを設けており、自分に合う方を選んで参加していただいています。</p>
                                    </div>
                                </div>
                            </section>
                        </section>
                    </section>
                    <!-- プログラム -->
                    <section class="daycareContents__program">
                        <div class="daycareContents__program__leading">
                            <p>グループ開催時間の各活動をプログラムと呼んでいます。グループ参加者と一緒に取り組むことも、時間内に個人で取り組むこともあります。</p>
                            <h3>自分に合いそうなプログラムを<br>探してみましょう。</h3>
                        </div>
                        <div class="daycareContents__program__cards">
                            <div class="daycareContents__program__cards__card">
                                <h4 class="daycareContents__program__cards__card__ttl">Art</h4>
                                <div class="daycareContents__program__cards__card__txt--art">
                                    <p>毎月、講師を招いて、書道教室、パステル画教室を実施。水彩画、コラージュなども行っています。作品はイベント時に展示したり、コンテストに出展。美術館やアート展に出かけることも。</p>
                                </div>
                            </div>
                            <div class="daycareContents__program__cards__card">
                                <h4 class="daycareContents__program__cards__card__ttl">おやつ作り</h4>
                                <div class="daycareContents__program__cards__card__txt--oyatsu">
                                    <p>手軽で簡単にできるおやつを作ります。美味しいおやつを食べながら、和気あいあいと楽しくゆっくり過ごしています。</p>
                                </div>
                            </div>
                            <div class="daycareContents__program__cards__card">
                                <h4 class="daycareContents__program__cards__card__ttl">MT（音楽療法）</h4>
                                <div class="daycareContents__program__cards__card__txt--mt">
                                    <p>MTはミュージックセラピー（音楽療法）の略。音楽を使って楽しく活動することを目的としています。楽器でパートを受け持つことで責任感が出たり、合唱ではみんなで1つのものをつくり上げる達成感があります。</p>
                                </div>
                            </div>
                            <div class="daycareContents__program__cards__card">
                                <h4 class="daycareContents__program__cards__card__ttl">調理・調理ミーティング</h4>
                                <div class="daycareContents__program__cards__card__txt--cooking">
                                    <p>お昼ごはんを、みんなで作って食べています。メニューは参加者で決めていて、誰にでもできる簡単なものです。午後は今日やったことを振り返ったり、次週のメニューを確認しながら、ゆっくりと過ごします。</p>
                                </div>
                            </div>
                            <div class="daycareContents__program__cards__card">
                                <h4 class="daycareContents__program__cards__card__ttl">ストレッチ</h4>
                                <div class="daycareContents__program__cards__card__txt--stretch">
                                    <p>運動不足の解消や心身のリラックス、筋肉をつけるなどを目的に簡単な運動を行います。柔軟運動、ダンベル体操、気功を少しずつ取り入れており、休憩をとりながら自分のペースで無理なくすすめていきます。</p>
                                </div>
                            </div>
                            <div class="daycareContents__program__cards__card">
                                <h4 class="daycareContents__program__cards__card__ttl">運動</h4>
                                <div class="daycareContents__program__cards__card__txt--exercise">
                                    <p>卓球やソフトバレーなど、毎週さまざまな運動を行っています。健康のため、仲間づくりのため、ストレス発散のためなど、自分の目的に合わせて参加できます。近隣の公園や体育館へ行くこともあります。</p>
                                </div>
                            </div>
                            <div class="daycareContents__program__cards__card">
                                <h4 class="daycareContents__program__cards__card__ttl">室内ゲーム</h4>
                                <div class="daycareContents__program__cards__card__txt--games">
                                    <p>Wii、ピンポン、すごろく、カードゲームなど、様々なゲームを行っています。みんなで和気あいあいとしながら、楽しい時間を過ごすことができます。</p>
                                </div>
                            </div>
                            <div class="daycareContents__program__cards__card">
                                <h4 class="daycareContents__program__cards__card__ttl">SST（社会生活技能訓練）</h4>
                                <div class="daycareContents__program__cards__card__txt--sst">
                                    <p>SSTはソーシャルスキルズトレーニング（社会生活技能訓練）の略。生活の悩みや、人間関係での困りごとをお互い話し合える場であり、「頼む」「断る」などといった、日常生活に必要なスキルを学ぶことができます。</p>
                                </div>
                            </div>
                            <div class="daycareContents__program__cards__card">
                                <h4 class="daycareContents__program__cards__card__ttl">サイコドラマ</h4>
                                <div class="daycareContents__program__cards__card__txt--psychodrama">
                                    <p>集団で行われる心理療法の一つで、台本のない即興劇をみんなで役割分担しながら行っていきます。活動を通し、自分自身を見つめたり、より良い人間関係を作ることを目的としています。</p>
                                </div>
                            </div>
                            <div class="daycareContents__program__cards__card">
                                <h4 class="daycareContents__program__cards__card__ttl">モテ講座</h4>
                                <div class="daycareContents__program__cards__card__txt--mote">
                                    <p>“モテ”につながる様々なスキルを考え、身につけていく講座です。月ごとにテーマを決めて取り組んでいきます。</p>
                                </div>
                            </div>
                            <div class="daycareContents__program__cards__card">
                                <h4 class="daycareContents__program__cards__card__ttl">生活講座</h4>
                                <div class="daycareContents__program__cards__card__txt--lifestyle">
                                    <p>身だしなみからお掃除の仕方、家庭の防災からより良い人づき合いまで、生活に関することを広く取り上げています。すぐに役立つ情報や、生活の知恵袋など実践を交えてレクチャーします。</p>
                                </div>
                            </div>
                            <div class="daycareContents__program__cards__card">
                                <h4 class="daycareContents__program__cards__card__ttl">作業</h4>
                                <div class="daycareContents__program__cards__card__txt--gardening">
                                    <p>園芸を中心に活動しており、花や野菜を育てています。その他、クリニック内外の清掃活動や、季節に合わせた小物作りもしています。</p>
                                </div>
                            </div>
                            <!-- <div class="daycareContents__program__cards__card">
                                <h4 class="daycareContents__program__cards__card__ttl">手芸</h4>
                                <div class="daycareContents__program__cards__card__txt--handicraft">
                                    <p>刺し子、ペーパーブロック、ビーズ細工など、希望に合わせて様々な手芸を行っています。物づくりを通して、新たな趣味を見つけたり、作品を完成させることで喜びや達成感を得ながら楽しく活動しています。</p>
                                </div>
                            </div> -->
                            <div class="daycareContents__program__cards__card">
                                <h4 class="daycareContents__program__cards__card__ttl">製作</h4>
                                <div class="daycareContents__program__cards__card__txt--production">
                                    <p>折り紙で季節の壁画を作ります。題材は、みんなで話し合って決めています。</p>
                                </div>
                            </div>
                            <div class="daycareContents__program__cards__card">
                                <h4 class="daycareContents__program__cards__card__ttl">茶話会</h4>
                                <div class="daycareContents__program__cards__card__txt--teaparty">
                                    <p>飲み物やお菓子を買って、のんびりとおしゃべりをしています。月１回のお楽しみ会では、ホットケーキを作ったり、近くの喫茶店に行ったりもします。</p>
                                </div>
                            </div>
                            <div class="daycareContents__program__cards__card">
                                <h4 class="daycareContents__program__cards__card__ttl">ぴーす</h4>
                                <div class="daycareContents__program__cards__card__txt--peace">
                                    <p>少人数のグループです。第１週にはミーティングを行い、来月の予定を決めます。第２週にはおやつ作りを行います。第３・４週には、みんなで話し合ってやりたいことを決めます。</p>
                                </div>
                            </div>
                            <div class="daycareContents__program__cards__card">
                                <h4 class="daycareContents__program__cards__card__ttl">自主プログラム</h4>
                                <div class="daycareContents__program__cards__card__txt--voluntary">
                                    <p>ぬり絵や読書、物作りなど、メンバーがそれぞれ好きなものを持ちより、様々な活動を行っています。</p>
                                </div>
                            </div>
                            <div class="daycareContents__program__cards__card">
                                <h4 class="daycareContents__program__cards__card__ttl">個別プログラム</h4>
                                <div class="daycareContents__program__cards__card__txt--iIndividual">
                                    <p>個人でやりたいことがある人や集団が苦手な人が、個別で活動できるプログラムです。読書、脳トレ、創作活動、散歩、TVゲーム、カードゲームなど行い、それぞれ自分の時間を過ごします。</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </section>
            </section>
            <p>※活動時間やプログラム内容は2020.7現在</p>
        </main>
</template>

<script>
import Meta from '../mixins/MetaSettable'

export default {
	mixins: [Meta],
	data() {
		return {
			isLoading: true,
			loadedCount: 0,
			cnt: 0,
			appClass: 'daycare_nightcare'
		}
	},
	mounted() {
		// this.cnt = document.images.length;
		this.cnt = document.querySelectorAll('main img').length;
		if(this.cnt == 0){
			this.isLoading = false;
		}
		this.$emit('appClassMethod', this.appClass);
		// グループ・プログラムタブ
        const daycareNavLis = document.querySelectorAll(".daycare__nav li"); // ナビ
        const daycareTabs = document.querySelectorAll(".daycareContents>section"); // タブ本体
        const daycareContents = document.querySelector(".daycareContents"); // デイケアコンテナ
        
        for(let i = 0; i < daycareNavLis.length; i++){
            (function(k){
                daycareNavLis[k].addEventListener("click", function(){
                        // console.log(k);
                        for(let j = 0; j < daycareNavLis.length; j++){
                            daycareNavLis[j].classList.remove('view');
                            daycareTabs[j].classList.remove('view');
                        }
                        daycareNavLis[k].classList.add('view');
                        // console.log(k);
                        daycareContents.style.height = daycareTabs[k].clientHeight + 'px';
                        daycareTabs[k].classList.add('view');
                        scrollIn();
                    },
                    false
                );
            })(i);
        }
        
        // グループ内タブ
        const daycareGroupNavLis = document.querySelectorAll(".daycareContents__group__nav li"); // ナビ
        const daycareGroupTabs = document.querySelectorAll(".daycareContents__group__contents>section"); // タブ本体
        const daycareContentsGroupTab = document.querySelector(".daycareContents__group"); // グループタブ
        // console.log(daycareContentsGroupTab.clientHeight);
        const daycareGroupContents = document.querySelector(".daycareContents__group__contents"); // グループ内コンテナ

        for(let i = 0; i < daycareGroupNavLis.length; i++){
            (function(k){
                let li = daycareGroupNavLis[k];
                li.addEventListener("click", function(){
                        // console.log(e,k);
                        for(let j = 0; j < daycareGroupNavLis.length; j++){
                            daycareGroupNavLis[j].classList.remove('view');
                            daycareGroupTabs[j].classList.remove('view');
                        }
                        li.classList.add('view');
                        // console.log(daycareGroupTabs[k]);
                        daycareGroupContents.style.height = daycareGroupTabs[k].clientHeight + 'px';
                        daycareGroupTabs[k].classList.add('view');
                        daycareContents.style.height = daycareContentsGroupTab.clientHeight + 'px';
                    },
                    false
                );
            })(i);
        }

        // 高さの初期化
        function initH(){
            let viewGroupTab = document.querySelector(".daycareContents__group__contents>.view");
            let viewDaycareTab = document.querySelector(".daycareContents>.view");
            daycareGroupContents.style.height = viewGroupTab.clientHeight + 'px';
            daycareContents.style.height = viewDaycareTab.clientHeight + 'px';
        }
        initH();

        // プログラムカード、スクロールイン時のエフェクト用クラス制御
        let programCrads = document.querySelectorAll(".daycareContents__program__cards__card");
        let wHeight = document.body.clientHeight;
        function scrollIn(){
            for(let i = 0; i < programCrads.length; i++){
                let card = programCrads[i];
                let rect = card.getBoundingClientRect();
                let scrollTop = window.pageYOffset || document.documentElement.scrollTop; // スクロール距離
                let rectTop = rect.top + scrollTop + 50; // 要素上端+調整値
                let rectLeft = rect.left;

                if (scrollTop > rectTop - wHeight + wHeight/100){
                    card.classList.add("view"); //
                    card.style.transitionDelay = rectLeft/1000 + 's';
                }else {
                    card.classList.remove("view"); //
                }
            }
        }
        window.addEventListener('scroll',function() {
            scrollIn();
        });

        // リサイズ時
        let daycareTimer = false;
        window.addEventListener('resize',function() {
            if (daycareTimer !== false) {
                clearTimeout(daycareTimer);
            }
            daycareTimer = setTimeout(function() {
                initH();
            }, 200);
        });
	},
	methods: {
		loaded () {
			this.loadedCount++;
			// console.log(this.loadedCount);;
			if(this.loadedCount === this.cnt) {
				this.isLoading = false;
			}
		}
	}
}
</script>