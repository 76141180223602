<template>
  <div id="mapcanvas" class="map" ref="googleMap01"></div>
</template>

<script type="text/javascript" src="https://maps.googleapis.com/maps/api/js?key=AIzaSyBWxMFopbpspu262sodcMUQb8K-oT0bW20"></script>
<script>
import GoogleMapsApiLoader from 'google-maps-api-loader';

export default {
  name: 'GoogleMap',
  data() {
    return {
      google: null,
      mapConfig: {
        center: {
          lat: 37.38630,
          lng: 140.3307800
        },
        zoom: 18
      }
    }
  },
  async mounted() {
    this.google = await GoogleMapsApiLoader({
      apiKey: 'AIzaSyBWxMFopbpspu262sodcMUQb8K-oT0bW20'
    });
    this.initializeMap();
  },
  methods: {
    initializeMap() {
      // new this.google.maps.Map(this.$refs.googleMap01, this.mapConfig);
      var map01 = new google.maps.Map(document.getElementById("mapcanvas"), this.mapConfig);
      var image = '/common/img/map_marker.png'; //マーカーアイコン画像URL
      var marker = new google.maps.Marker({
        position: {
          lat: 37.38630,
          lng: 140.3307800
        },
        map: map01,
        icon:image, //アイコン画像をセット
      });
    }
  }
}
</script>