<template>
	<main>
		<div id="loader" v-if="isLoading"><div id="loader__wheel" ><p><span></span>LOADING...</p></div></div>
		<section class="mv">
			<h1>スタッフ募集</h1>
		</section>
		<table>
			<tbody>
				<tr>
					<td>公認心理師（取得見込可）</td>
					<td>常勤（非常勤も可）&emsp;1名</td>
				</tr>
				<tr>
					<td>看護師（正・准）</td>
					<td>常勤（非常勤も可）&emsp;1名</td>
				</tr>
				<!-- <tr>
					<td>精神保健福祉士</td>
					<td>常勤（非常勤も可）&emsp;1名</td>
				</tr> -->
				<tr>
					<td>介護福祉士</td>
					<td>常勤（非常勤も可）&emsp;1名</td>
				</tr>
			</tbody>
		</table>
		<p>※詳しくは当クリニックまでお電話でお問い合わせください。</p>
		<p>電話番号&emsp;<a href="tel:0249663300">024-966-3300</a></p>
	</main>
</template>

<script>
import Meta from '../mixins/MetaSettable'

export default {
	mixins: [Meta],
	data() {
		return {
			isLoading: true,
			loadedCount: 0,
			cnt: 0,
			appClass: 'staff_recruitment'
		}
	},
	mounted() {
		// this.cnt = document.images.length;
		this.cnt = document.querySelectorAll('main img').length;
		if(this.cnt == 0){
			this.isLoading = false;
		}
		this.$emit('appClassMethod', this.appClass);
	},
	methods: {
		loaded () {
			this.loadedCount++;
			// console.log(this.loadedCount);;
			if(this.loadedCount === this.cnt) {
				this.isLoading = false;
			}
		}
	}
}
</script>