<template>
  <div>
		<div id="loader" v-if="isLoading"><div id="loader__wheel" ><p><span></span>LOADING...</p></div></div>
		<h1 style="margin-top:64px;">404 NotFound</h1>
		<p style="text-align:left; max-width:90%; margin:auto;">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aut excepturi eius atque nisi assumenda, sequi porro earum quaerat voluptatem animi in iste suscipit tempore reiciendis debitis beatae rerum, tempora qui!</p>
  </div>
</template>

<script>
import Meta from '../mixins/MetaSettable'

export default {
	mixins: [Meta],
	data() {
		return {
			isLoading: true,
			loadedCount: 0,
			cnt: 0,
			appClass: 'notFound'
		}
	},
	mounted() {
		// this.cnt = document.images.length;
		this.cnt = document.querySelectorAll('main img').length;
		if(this.cnt == 0){
			this.isLoading = false;
		}
		this.$emit('appClassMethod', this.appClass);
	},
	methods: {
		loaded () {
			this.loadedCount++;
			// console.log(this.loadedCount);;
			if(this.loadedCount === this.cnt) {
				this.isLoading = false;
			}
		}
	}
}
</script>