<template>
	<main>
		<div id="loader" v-if="isLoading"><div id="loader__wheel" ><p><span></span>LOADING...</p></div></div>

		<section class="mv">
			<h1>サイト運用について</h1>
		</section>

		<h2>当院における基本理念</h2>
		<section class="philosophy">
			<ol>
				<li>当院は個人情報保護に関する基本方針を策定し、全ての職員はそれを遵守し、患者様の個人情報の管理を行っております。</li>
				<li>診療・健康管理及び病院の運営管理に必要な範囲においてのみ、患者様の個人情報を収集しております。</li>
				<li>患者様から取得した個人情報は、原則として患者様ご本人の診療・健康管理の目的以外に使用いたしません。また、患者様の診療・健康管理に関係ない第三者への提供はいたしません。ただし、以下の場合において、第三者への提供を行う場合があります。
					<ul>
						<li>法令の定める第三者への提供の範囲において診療上必要に応じて他の医師等の意見を必要とする場合</li>
						<li>かかりつけ医（紹介医）と当院の担当医師との間で診療情報を共有する場合</li>
						<li>臨床検査及び保険請求等で個人情報を外部に委託する場合</li>
					</ul>
				</li>
				<li>個人情報の第三者への提供に関しては、内部規定の基準に準じている業者を委託先に選定し、委託業者契約時に個人情報保護に関する取り決めを行っております。なお、患者様からの要求に応じて、第三者への提供を停止することが出来ます。</li>
				<li>患者様の個人情報を適正に取り扱うために責任者を置き、委員会を設置して職員の個人情報保護に関する認識の向上を目的とした教育を行っております。</li>
				<li>患者様の個人情報への不正アクセス、紛失、破壊、改ざん及び漏えいを防止し、安全で正確な管理を行うよう努めています。</li>
				<li>個人情報保護に関する日本の法令及びその他の規範、内部規定の遵守に努めます。</li>
				<li>患者様ご本人からの要求に応じて、個人情報の停止、開示、訂正、削除等の要求には、個人情報保護窓口を設置し、適切に対応しております。</li>
				<li>また、個人情報の取り扱いに関する苦情や上記の基本方針に関するお問い合わせも、個人情報保護窓口にて対応しております。</li>
			</ol>
		</section>

		<h2>当院における個人情報の利用目的</h2>
		<section class="aboutPersonalInformation columns_2">
			<div>
				<h3>１ 医療提供</h3>
				<ol>
					<li>患者さまに提供する医療サービス</li>
					<li>他の病院等の医療機関、介護サービス事業者との連携</li>
					<li>他の医療機関等からの照会への回答</li>
					<li>患者さまの診療等のため、外部の医師等の意見・助言を求める場合</li>
					<li>検体検査業務等の業務委託</li>
					<li>ご家族等への病状説明</li>
					<li>その他、患者さまへの医療提供に関する利用</li>
				</ol>
				<h3>２ 診療費請求のための事務</h3>
				<ol>
					<li>当院での医療・介護労災保険、公費負担医療に関する事務及びその委託</li>
					<li>審査支払機関へのレセプトの提出</li>
					<li>審査支払機関又は保険者からの照会への回答</li>
					<li>公費負担医療に関する行政機関等へのレセプトの提出、照会への回答</li>
					<li>その他、医療・介護・労災保険、及び公費負担医療に関する診療費請求のための利用</li>
				</ol>
			</div>
			<div>
				<h3>３ 当院の管理運営業務</h3>
				<ol>
					<li>会計・経理</li>
					<li>医療事故等への報告</li>
					<li>当該患者さまへの医療サービスの向上</li>
					<li>その他、当院の管理運営業務に関する利用
						<ul>
							<li>企業等から委託を受けて行う健康診断等における、企業等への結果の通知</li>
							<li>医師賠償責任保険などに係る、医療に関する専門の団体、保険会社等への相談又は届出等</li>
							<li>医療・介護サービスや業務の維持・改善のための基礎資料</li>
							<li>当院内において行われる医療実習への協力</li>
							<li>医療の質の向上を目的とした当院内での症例研究</li>
							<li>外部監査機関への情報提供</li>
						</ul>
					</li>
				</ol>
			</div>
		</section>
	</main>
</template>

<script>
import Meta from '../mixins/MetaSettable'

export default {
	mixins: [Meta],
	data() {
		return {
			isLoading: true,
			loadedCount: 0,
			cnt: 0,
			appClass: 'administrator'
		}
	},
	mounted() {
		// this.cnt = document.images.length;
		this.cnt = document.querySelectorAll('main img').length;
		if(this.cnt == 0){
			this.isLoading = false;
		}
		this.$emit('appClassMethod', this.appClass);
	},
	methods: {
		loaded () {
			this.loadedCount++;
			// console.log(this.loadedCount);;
			if(this.loadedCount === this.cnt) {
				this.isLoading = false;
			}
		}
	}
}
</script>