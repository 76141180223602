import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import VueHead from  'vue-head'

Vue.config.productionTip = false
Vue.use(VueHead)

new Vue({
	router,
	store,
	render: h => h(App),
	head: {  
		meta: [  
			{name: 'description', content: 'デフォルトのdescriptionです。'},  
			{property: 'og:type', content: 'website'},  
			// Twitter  
			{ name: 'twitter:title', content: 'Content Title' },  
			// with shorthand  
			{ n: 'twitter:description', c: 'Content description less than 200 characters'},  
			// Facebook / Open Graph  
			{ property: 'fb:app_id', content: '123456789' },  
			{ property: 'og:title', content: 'Content Title' },  
			// with shorthand  
			{ p: 'og:image', c: 'https://xxxx.xx/image.jpg' },  
		],  
	}
}).$mount('#app')
