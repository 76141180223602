<template>
	<main>
		<div id="loader" v-if="isLoading"><div id="loader__wheel" ><p><span></span>LOADING...</p></div></div>

		<section class="mv">
			<h1>入院のご案内</h1>
		</section>
		<p class="attention">平成31年４月をもって入院を休止しました。よって<span>新規入院受付も行いません</span>のでご注意下さい。</p>
		<!-- <p>様々な理由で休むことができなくなった方が、自宅を離れて休養するための休息入院が中心になっています。ただし症状が強く当院での入院診療が困難な場合は、他の精神科病院を紹介させていただきます。</p>

		<section class="HospitalizationAbout columns_2">
			<figure>
				<img @load="loaded" src="../../assets/ph_hospitalization_01.png" alt="">
				<figcaption>個室イメージ図</figcaption>
			</figure>
			<figure>
				<img @load="loaded" src="../../assets/ph_hospitalization_02.png" alt="">
				<figcaption>病棟ホール</figcaption>
			</figure>
		</section>

		<table class="HospitalizationAbout__spec">
			<tr>
				<th>病室</th>
				<td>7床</td>
			</tr>
		</table> -->
	</main>
</template>

<script>
import Meta from '../mixins/MetaSettable'

export default {
	mixins: [Meta],
	data() {
		return {
			isLoading: true,
			loadedCount: 0,
			cnt: 0,
			appClass: 'hospitalization'
		}
	},
	mounted() {
		// this.cnt = document.images.length;
		this.cnt = document.querySelectorAll('main img').length;
		if(this.cnt == 0){
			this.isLoading = false;
		}
		this.$emit('appClassMethod', this.appClass);
	},
	methods: {
		loaded () {
			this.loadedCount++;
			// console.log(this.loadedCount);;
			if(this.loadedCount === this.cnt) {
				this.isLoading = false;
			}
		}
	}
}
</script>