<template>
	<main>
		<div id="loader" v-if="isLoading"><div id="loader__wheel" ><p><span></span>LOADING...</p></div></div>
		<section class="mv">
			<h1>医師紹介</h1>
		</section>
		
		<section class="doctorsIntro">
			<h2 class="doctorsIntro__title">鳥には空を、魚には水を、<br>そして人には社会を。</h2>
			<p class="doctorsIntro__name doctorsName">
				<span class="doctorsName__position">創設者</span>
				菅野&emsp;圭樹
				<span class="doctorsName__profession">医師</span>
			</p>
			<p class="doctorsIntro__photo"><img @load="loaded" src="../../assets/ph_doctor_sugano.png" alt=""></p>
			<div class="doctorsIntro__message">
				<p>すがのクリニックは、「鳥には空を、魚には水を、人には社会を」の開業理念に基づき、統合失調症やうつ病などの精神障害を持つ方々の地域生活を支援するために昭和63年4月、福島県郡山市に開院しました。<br>
				医療的な支援を行うためのクリニックでは、外来診療だけではなく、リハビリテーションを行うためのデイケアおよびナイトケア、在宅支援を行うための訪問看護、休息入院のための入院施設も有しています。さらに生活を支援するための共同住居、グループホーム、共同作業所を運営してきました（現在、グループホーム、共同作業所はNPO法人ペンギン村が運営しています）。このような幅広い支援を行うために、さまざまな職種の人たちが協力して、幅広い分野で活動を展開しています。<br>
				平成16年に開催された第100回日本精神神経科学会にて、これまでのすがのクリニックの活動が評価され、医療奨励賞を受賞しました。</p>
			</div>
		</section>

		<section class="doctorInfo">
			<div class="doctorInfo__name">
				<p class="doctorsName">
					<span class="doctorsName__position">院長</span>
					鈴木&emsp;実穂
					<span class="doctorsName__rofession">医師</span>
				</p>
			</div>
			<div class="doctorInfo__spec">
				<dl>
					<dt>出身大学</dt>
					<dd>弘前大学</dd>
					<dt>趣味</dt>
					<dd>もの作り/グッズ集め</dd>
				</dl>
				<p class="doctorInfo__spec__toOutPatient miho"><router-link to="/outpatient/">外来のご案内</router-link></p>
			</div>
			<div class="doctorInfo__tot">
				<table class="outpatient__tot--miho">
					<caption>鈴木実穂（第１診療室）</caption>
					<thead>
						<tr>
							<th>診療時間</th>
							<th>月</th>
							<th>火</th>
							<th>水</th>
							<th>木</th>
							<th>金</th>
							<th>土</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>9：00～12：00</td>
							<td>●</td>
							<td>●</td>
							<td></td>
							<td>●</td>
							<td>●</td>
							<td>●</td>
						</tr>
						<tr>
							<td>2：00～5：00</td>
							<td>●</td>
							<td></td>
							<td></td>
							<td>●</td>
							<td></td>
							<td>●</td>
						</tr>
						<tr>
							<td>6：00～8：00</td>
							<td></td>
							<td>●</td>
							<td></td>
							<td></td>
							<td>●</td>
							<td></td>
						</tr>
					</tbody>
				</table>
			</div>
			<p class="doctorInfo__photo"><img @load="loaded" src="../../assets/ph_doctor_miho.png" alt=""></p>
		</section>

		<section class="doctorInfo">
			<div class="doctorInfo__name">
				<p class="doctorsName">
					<span class="doctorsName__position">理事長</span>
					鈴木&emsp;章市
					<span class="doctorsName__rofession">医師</span>
				</p>
			</div>
			<div class="doctorInfo__spec">
				<dl>
					<dt>出身大学</dt>
					<dd>弘前大学</dd>
					<dt>趣味</dt>
					<dd>SF映画鑑賞</dd>
				</dl>
				<p class="doctorInfo__spec__toOutPatient akiichi"><router-link to="/outpatient/">外来のご案内</router-link></p>
			</div>
			<div class="doctorInfo__tot">
				<table class="outpatient__tot--akiichi">
					<caption>鈴木章市（第２診療室）</caption>
					<thead>
						<tr>
							<th>診療時間</th>
							<th>月</th>
							<th>火</th>
							<th>水</th>
							<th>木</th>
							<th>金</th>
							<th>土</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>9：00～12：00</td>
							<td>●</td>
							<td>●</td>
							<td></td>
							<td>●</td>
							<td></td>
							<td>●</td>
						</tr>
						<tr>
							<td>2：00～5：00</td>
							<td>●</td>
							<td></td>
							<td></td>
							<td>●</td>
							<td></td>
							<td>●</td>
						</tr>
						<tr>
							<td>6：00～8：00</td>
							<td></td>
							<td>●</td>
							<td></td>
							<td></td>
							<td>●</td>
							<td></td>
						</tr>
					</tbody>
				</table>
			</div>
			<p class="doctorInfo__photo"><img @load="loaded" src="../../assets/ph_doctor_akiichi.png" alt=""></p>
		</section>

		<section class="doctorInfo">
			<div class="doctorInfo__name">
				<p class="doctorsName">
					白木澤&emsp;史子
					<span class="doctorsName__rofession">医師</span>
				</p>
			</div>
			<div class="doctorInfo__spec">
				<dl>
					<dt>出身大学</dt>
					<dd>岩手医科大学</dd>
					<dt>趣味</dt>
					<dd>機織り/フェルト工作</dd>
				</dl>
				<p class="doctorInfo__spec__toOutPatient shirokizawa"><router-link to="/outpatient/">外来のご案内</router-link></p>
			</div>
			<div class="doctorInfo__tot">
				<table class="outpatient__tot--shirokizawa">
					<caption>白木澤史子（第３診療室）</caption>
					<thead>
						<tr>
							<th>診療時間</th>
							<th>月</th>
							<th>火</th>
							<th>水</th>
							<th>木</th>
							<th>金</th>
							<th>土</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>9：00～12：00</td>
							<td>●</td>
							<td></td>
							<td></td>
							<td>●</td>
							<td>●</td>
							<td>●</td>
						</tr>
						<tr>
							<td>2：00～5：00</td>
							<td>●</td>
							<td></td>
							<td></td>
							<td>●</td>
							<td></td>
							<td>●</td>
						</tr>
						<tr>
							<td>6：00～8：00</td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
							<td>●</td>
							<td></td>
						</tr>
					</tbody>
				</table>
			</div>
			<p class="doctorInfo__photo"><img @load="loaded" src="../../assets/ph_doctor_shirokizawa.png" alt=""></p>
		</section>
	</main>
</template>

<script>
import Meta from '../mixins/MetaSettable'

export default {
	mixins: [Meta],
	data() {
		return {
			isLoading: true,
			loadedCount: 0,
			cnt: 0,
			appClass: 'doctors'
		}
	},
	mounted() {
		// this.cnt = document.images.length;
		this.cnt = document.querySelectorAll('main img').length;
		if(this.cnt == 0){
			this.isLoading = false;
		}
		this.$emit('appClassMethod', this.appClass);
	},
	methods: {
		loaded () {
			this.loadedCount++;
			// console.log(this.loadedCount);;
			if(this.loadedCount === this.cnt) {
				this.isLoading = false;
			}
		}
	}
}
</script>