<template>
	<main>
		<div id="loader" v-if="isLoading"><div id="loader__wheel" ><p><span></span>LOADING...</p></div></div>

		<section class="mv">
			<h1>カウンセリング</h1>
		</section>
		
		<section class="counselingIntro columns_2">
			<div>
				<p>臨床心理士によるカウンセリングです。人間関係の悩みや不安な気持ち、ストレスなどの「心の健康」の問題を抱えている方々が、その人自身で問題を解決し、その人らしく生き生きと生活できるようお手伝いをします。<br>
				カウンセリングは、当クリニック「外来」通院の方で、必要に応じて実施しております。</p>
			</div>
			<figure><img @load="loaded" src="../../assets/ph_counseling_01.png" alt=""></figure>
		</section>

		<section class="counselingMenu">
			<h3>相談内容</h3>
			<div class="counselingMenu__item">
				<div class="counselingMenu__item--work">
					<h4>仕事・職場の悩み</h4>
					<p>職場の人間関係で悩んでいる、仕事のストレスが多くて困っているなど</p>
				</div>
			</div>
			<div class="counselingMenu__item">
				<div class="counselingMenu__item--home">
					<h4>家庭の悩み</h4>
					<p>親子関係や夫婦関係で悩んでいる、育児に関して困っているなど</p>
				</div>
			</div>
			<div class="counselingMenu__item">
				<div class="counselingMenu__item--school">
					<h4>学校での悩み</h4>
					<p>勉強する意欲がわかない、学校に行きたくない、友だちとうまく関われないなど</p>
				</div>
			</div>
			<div class="counselingMenu__item">
				<div class="counselingMenu__item--others">
					<h4>その他</h4>
					<p>生活における様々な悩みや不安など</p>
				</div>
			</div>
		</section>

		<section class="counselingUtilization columns_2">
			<div>
				<h3>実施内容</h3>
				<h4>カウンセリング</h4>
				<p>職場や家庭、学業などの悩みや適応上の問題を持つ人に対して、日々の生活が送りやすくなるような援助や、自己洞察を深めて自分への気づきや新しい見方ができるような援助を行います。</p>
				<h4>その他</h4>
				<p>必要に応じて、知能検査や性格検査など、各種心理検査も行っています。</p>
			</div>
			<div>
				<h3>実施曜日・時間</h3>
				<!-- <h4>曜日・時間</h4> -->
				<p>来談者の事情、都合を考慮した上で、カウンセリング担当者と相談の上で日時を決定します。1回あたり50分の枠で行っています。 <br>
				詳しくはお問い合わせ下さい。</p>
			</div>
		</section>
	</main>
</template>

<script>
import Meta from '../mixins/MetaSettable'

export default {
	mixins: [Meta],
	data() {
		return {
			isLoading: true,
			loadedCount: 0,
			cnt: 0,
			appClass: 'counseling'
		}
	},
	mounted() {
		// this.cnt = document.images.length;
		this.cnt = document.querySelectorAll('main img').length;
		if(this.cnt == 0){
			this.isLoading = false;
		}
		this.$emit('appClassMethod', this.appClass);
	},
	methods: {
		loaded () {
			this.loadedCount++;
			// console.log(this.loadedCount);;
			if(this.loadedCount === this.cnt) {
				this.isLoading = false;
			}
		}
	}
}
</script>