<template>
	<main>
		<div id="loader" v-if="isLoading"><div id="loader__wheel" ><p><span></span>LOADING...</p></div></div>
		<section class="mv">
			<h1>訪問看護</h1>
		</section>
		<p>訪問看護は、こころの病を抱えている方々やそのご家族に対して、地域で生活していくために必要な支援を行うために、当クリニックの看護師や精神保健福祉士がお住まいを訪問します。利用手続きは、まず「外来」でご相談させていただき、通院しながらのご利用となります。</p>
		
		<section class="visitingNursingAbout columns_2">
			<div>
				<h3>どのような方が利用されていますか。</h3>
				<!-- <figure><img @load="loaded" src="../../assets/ph_visiting_nursing_01.png" alt=""></figure> -->
				<ul>
					<li>病気に対する不安を持っている方</li>
					<li>治療を続けることが途絶えがちになっている方</li>
					<li>病気とつきあいながら地域での生活を継続しようとしている方</li>
					<li>身の回りのことや家事などがうまくできない方</li>
					<li>人とうまく話をしたり、お付き合いすることが苦手な方</li>
					<li>話し相手がいなくて寂しい方</li>
					<li>患者様にどのように対応したらよいか分からないご家族</li>
					<li>日常生活への様々な不安を抱える方とそのご家族</li>
				</ul>
			</div>
			<div>
				<h3>どのようなサービスが受けられますか。</h3>
				<figure><img @load="loaded" src="../../assets/ph_visiting_nursing_02.png" alt=""></figure>
				<ul>
					<li>健康に関する相談（身体症状・睡眠状況・服薬状況など）</li>
					<li>清潔・身だしなみに関する相談</li>
					<li>お金の使い方に関する相談</li>
					<li>人とのつきあいに関する相談</li>
					<li>日課や余暇の過ごし方に関する相談</li>
					<li>家族への相談や支援</li>
					<li>公共機関や行政機関、交通機関、金融機関などの情報提供と社会資源活用の支援</li>
				</ul>
			</div>
		</section>

		<section class="visitingNursingUtilization columns_3">
			<div>
				<h3>料金はどれくらいかかりますか</h3>
				<ul>
					<li>自立支援医療がご利用できます。そのためご利用にかかる費用は個人差がありますので、詳しくは受付または看護師にお問い合わせ下さい。</li>
					<li>訪問する場所までの距離により異なります。その分の自己負担となります。</li>
				</ul>
			</div>
			<div>
				<h3>利用回数や訪問日程はどのようになりますか。</h3>
				<ul>
					<li>1週間に3回までご利用できます。利用目的やご要望に応じてご利用回数や日程を調整いたします。</li>
				</ul>
			</div>
			<div>
				<h3>その他</h3>
				<ul>
					<li>個人の秘密は厳守いたします。</li>
					<li>1週間に3回までご利用できます。</li>
					<li>ご利用を希望される方は、主治医にご相談下さい。</li>
				</ul>
			</div>
		</section>
	</main>
</template>

<script>
import Meta from '../mixins/MetaSettable'

export default {
	mixins: [Meta],
	data() {
		return {
			isLoading: true,
			loadedCount: 0,
			cnt: 0,
			appClass: 'visiting_nursing'
		}
	},
	mounted() {
		// this.cnt = document.images.length;
		this.cnt = document.querySelectorAll('main img').length;
		if(this.cnt == 0){
			this.isLoading = false;
		}
		this.$emit('appClassMethod', this.appClass);
	},
	methods: {
		loaded () {
			this.loadedCount++;
			// console.log(this.loadedCount);;
			if(this.loadedCount === this.cnt) {
				this.isLoading = false;
			}
		}
	}
}
</script>