<template>
	<div id="app" v-bind:class="{[appClass]: appClass}">
		<header><!-- header -->
            <p class="headerLogo"><router-link to="/">医療法人慈圭会 すがのクリニック</router-link></p>
            <ul class="headerNav__sub">
                <router-link tag="li" to="/for_beginners/" class="forBeginers"><a>初めての方へ</a></router-link>
                <li class="contactUs"><a href="tel:024-966-3300">お問い合わせ</a></li>
            </ul>
            <nav class="headerNav">
                <ul class="headerNav__primary">
                    <router-link tag="li" to="/"><a>トップページ<span>Toppage</span></a></router-link>
					          <router-link tag="li" to="/doctors/"><a>医師紹介<span>Introduction of Doctors</span></a></router-link>
                    <router-link tag="li" to="/hospital/"><a>院内各課の紹介<span>Introduction of Hospital Departments</span></a></router-link>
                    <router-link tag="li" to="/daycare_nightcare/"><a>デイケア・ナイトケア<span>Day Care / Night Care</span></a></router-link>
                    <router-link tag="li" to="/visiting_nursing/"><a>訪問看護<span>Visiting Nursing</span></a></router-link>
                    <!-- <router-link tag="li" to="/hospitalization/"><a>入院のご案内<span>Information on Hospitalization</span></a></router-link> -->
                    <router-link tag="li" to="/counseling/"><a>カウンセリング<span>Counseling</span></a></router-link>
                    <router-link tag="li" to="/outpatient/"><a>外来のご案内<span>Outpatient Information</span></a></router-link>
                </ul>
                <ul class="headerNav__secondary">
					<router-link tag="li" to="/for_beginners/"><a>初めての方へ</a></router-link>
					<router-link tag="li" to="/staff_recruitment/"><a>スタッフ募集</a></router-link>
					<router-link tag="li" to="/access/"><a>アクセス</a></router-link>
					<router-link tag="li" to="/administrator/"><a>サイト運用について</a></router-link>
                </ul>
                <p id="miniLogo"><router-link to="/">&nbsp;</router-link></p>
            </nav>
            <p class="headerNavCtrl">
                <span class="header__nav__ctrl__line--One">&nbsp;</span>
                <span class="header__nav__ctrl__line--Two">&nbsp;</span>
                <span class="header__nav__ctrl__line--Three">&nbsp;</span>
                <span class="header__nav__ctrl__txt">MENU</span>
            </p>
        </header>
		<transition mode="out-in">
			<router-view @appClassMethod="updateappClass"/>
		</transition>
		<footer>
        <!-- <div> -->
          <GoogleMap v-if="appClass == 'access'" />
        <!-- </div> -->
        <div class="footerInner">
            <nav>
                <ul>
        <router-link tag="li" to="/staff_recruitment/"><a>スタッフ募集</a></router-link>
        <router-link tag="li" to="/access/"><a>アクセス</a></router-link>
        <router-link tag="li" to="/administrator/"><a>サイト運用について</a></router-link>
                </ul>
            </nav>
            <p class="footerLogo">医療法人慈圭会 すがのクリニック</p>
            <address>
                <p>〒963-0209 郡山市御前南６丁目16番地 <!--<br>FAX:024-966-3800--></p>
                <p class="tel"><span>TEL</span><a href="tel:024-966-3300">024-966-3300</a></p>
                <p class="time">FAX:024-966-3800</p>
                <!-- <p class="time">受付時間／<br class="pc">平日9:00～18:00<br class="sp">（年末年始・長期休暇除く）</p> -->
            </address>
        </div>
        <small>Copyright ©2020 SUGANO CLINIC All Rights Reserved.</small>
    </footer>
	</div>
</template>

<script>
import GoogleMap from './components/GoogleMap.vue'
// import GoogleMapsApiLoader from 'google-maps-api-loader';

export default {
	data() {
    // name: 'Map', // googleMap
    return {
      text: this.$route.name,
      isHome: true,
      appClass: '',
      // google: null, // googleMap
      // mapConfig: {   // googleMap
      //   center: {
      //     lat: 35.68944,
      //     lng: 139.69167
      //   },
      //   zoom: 17
      // }
    }
  },
	components: {
    // Navbar,
    GoogleMap
  },
  // async mounted() {
  //   this.google = await GoogleMapsApiLoader({ // googleMap
  //     apiKey: 'AIzaSyBWxMFopbpspu262sodcMUQb8K-oT0bW20'
  //   });
  //   this.initializeMap(); // googleMap
  // },
	methods:{
		updateappClass (appClass) {
			this.appClass = appClass;
    },
    // initializeMap() { // googleMap
    //   new this.google.maps.Map(this.$refs.googleMap, this.mapConfig);
    // }
	}
}
</script>

<style>

/* .map { // googleMap
  width: 100vw;
  height: 100vh;
} */
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
} */

/* mode="out-in" */
.v-enter-active, .v-leave-active, .container {
    transition: opacity .5s;
}
.v-enter, .v-leave-to, .container.is-hide {
    opacity: 0;
}

/* name="view" */
/* .view-enter-active, .view-leave-active {
  transition: opacity 0.5s;
}
.view-leave-active {
  position: absolute;
}
.view-enter, .view-leave-to {
  opacity: 0;
} */
</style>
