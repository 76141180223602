import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/pages/Home.vue'
// import About from '../views/pages/About.vue'
import Doctors from '../views/pages/Doctors.vue'
import Hospital from '../views/pages/Hospital.vue'
import Daycare_nightcare from '../views/pages/Daycare_nightcare.vue'
import Visiting_nursing from '../views/pages/Visiting_nursing.vue'
import Hospitalization from '../views/pages/Hospitalization.vue'
import Counseling from '../views/pages/Counseling.vue'
import Outpatient from '../views/pages/Outpatient.vue'
import For_beginners from '../views/pages/For_beginners.vue'
import Staff_recruitment from '../views/pages/Staff_recruitment.vue'
import Access from '../views/pages/Access.vue'
import Administrator from '../views/pages/Administrator.vue'
import NotFound from '../views/pages/NotFound.vue'
import VueAnalytics from 'vue-analytics'

Vue.use(VueRouter)

  const routes = [
  {
    path: '/',
    name: 'Home',
    meta: { title: 'すがのクリニック', desc: '福島県郡山市で地域に根ざした精神科医療を展開し20年｜すがのクリニック'  },
    component: Home
  },
  {
    path: '/doctors/',
    name: 'Doctors',
    meta: { title: '医師紹介｜すがのクリニック', desc: '福島県郡山市で地域に根ざした精神科医療を展開し20年｜すがのクリニック' },
    component: Doctors
  },
  {
    path: '/hospital/',
    name: 'Hospital',
    meta: { title: '院内各課の紹介｜すがのクリニック', desc: '福島県郡山市で地域に根ざした精神科医療を展開し20年｜すがのクリニック' },
    component: Hospital
  },
  {
    path: '/daycare_nightcare/',
    name: 'Daycare_nightcare',
    meta: { title: 'デイケア・ナイトケア｜すがのクリニック', desc: '福島県郡山市で地域に根ざした精神科医療を展開し20年｜すがのクリニック' },
    component: Daycare_nightcare
  },
  {
    path: '/visiting_nursing/',
    name: 'Visiting_nursing',
    meta: { title: '訪問看護｜すがのクリニック', desc: '福島県郡山市で地域に根ざした精神科医療を展開し20年｜すがのクリニック' },
    component: Visiting_nursing
  },
  {
    path: '/hospitalization',
    name: 'Hospitalization',
    meta: { title: '入院のご案内｜すがのクリニック', desc: '福島県郡山市で地域に根ざした精神科医療を展開し20年｜すがのクリニック' },
    component: Hospitalization
  },
  {
    path: '/counseling/',
    name: 'Counseling',
    meta: { title: 'カウンセリング｜すがのクリニック', desc: '福島県郡山市で地域に根ざした精神科医療を展開し20年｜すがのクリニック' },
    component: Counseling
  },
  {
    path: '/outpatient/',
    name: 'Outpatient',
    meta: { title: '外来のご案内｜すがのクリニック', desc: '福島県郡山市で地域に根ざした精神科医療を展開し20年｜すがのクリニック' },
    component: Outpatient
  },
  {
    path: '/for_beginners/',
    name: 'For_beginners',
    meta: { title: '初めての方へ｜すがのクリニック', desc: '福島県郡山市で地域に根ざした精神科医療を展開し20年｜すがのクリニック' },
    component: For_beginners
  },
  {
    path: '/staff_recruitment/',
    name: 'Staff_recruitment',
    meta: { title: 'スタッフ募集｜すがのクリニック', desc: '福島県郡山市で地域に根ざした精神科医療を展開し20年｜すがのクリニック' },
    component: Staff_recruitment
  },
  {
    path: '/access/',
    name: 'Access',
    meta: { title: 'アクセス｜すがのクリニック', desc: '福島県郡山市で地域に根ざした精神科医療を展開し20年｜すがのクリニック' },
    component: Access
  },
  {
    path: '/administrator/',
    name: 'Administrator',
    meta: { title: 'サイト運用について｜すがのクリニック', desc: '福島県郡山市で地域に根ざした精神科医療を展開し20年｜すがのクリニック' },
    component: Administrator
  },
  {
    path: '*',
    name: 'NotFound',
    meta: { title: '404｜すがのクリニック', desc: 'This is 404 page.' },
    component: NotFound
  }
  // {
  //   path: '/about',
  //   name: 'About',
  //   meta: { title: 'About', desc: 'This is About page.' },
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

Vue.use(VueAnalytics, {
  id: 'UA-180570481-1',
  router
})

export default router
