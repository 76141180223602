<template>
	<main>
		<div id="loader" v-if="isLoading"><div id="loader__wheel" ><p><span></span>LOADING...</p></div></div>

		<section class="mv">
			<h1>初めての方へ</h1>
		</section>
		<h2>まずは、お電話にてご確認お願い致します。</h2>

		<section class="beginersContact">
			<p class="beginersContact__tel"><span>TEL.</span>024-966-3300</p>
			<p>初めての方は、突然ご来院されても当日の診察はできないことがあります。 また長時間お待ち頂くことがあります。<br>
			誠に申し訳ありませんがご了承下さい。</p>
		</section>

		<section class="columns_2">
			<div>
				<h3>電話では、以下のことについてお話下さい。</h3>
				<ul>
					<li>受診理由（困っていること、悩んでいること）</li>
					<li>お名前、年齢、性別</li>
					<li>連絡先お電話番号</li>
				</ul>
			</div>
			<div>
				<h3>初診当日にお持ちいただく物</h3>
				<ul>
					<li>保険証</li>
					<li>紹介状（他院に通院している方のみ）</li>
					<li>お薬手帳（他院に通院している方のみ、内科などの薬も含めて）<br>
						※当院は保険診療を行っておりますが、保険証をお忘れの場合は全額自費にてお支払い頂きます。</li>
				</ul>
			</div>
		</section>

		<section>
			<h2>予約診療ご協力のお願い</h2>
			<p>当院では予約制を実施させて頂いておりますが、患者様には待ち時間が長くなっており、ご迷惑をおかけしております。ご理解ご協力の程よろしくお願い申し上げます。<br>
			受付にある「診察申込書」に記入し、「診察券」を添えて受付してください。 月初めには、保険証もお出し下さい。<br>
			診察終了後、会計にて処方箋および領収書をお渡しします。会計時に次回の予約をお願い致します。
			<!-- <a href="#" class="dlBtn">「診察申込書」PDFダウンロード</a> -->
			</p>
		</section>
	</main>
</template>

<script>
import Meta from '../mixins/MetaSettable'

export default {
	mixins: [Meta],
	data() {
		return {
			isLoading: true,
			loadedCount: 0,
			cnt: 0,
			appClass: 'for_beginners'
		}
	},
	mounted() {
		// this.cnt = document.images.length;
		this.cnt = document.querySelectorAll('main img').length;
		if(this.cnt == 0){
			this.isLoading = false;
		}
		this.$emit('appClassMethod', this.appClass);
	},
	methods: {
		loaded () {
			this.loadedCount++;
			// console.log(this.loadedCount);;
			if(this.loadedCount === this.cnt) {
				this.isLoading = false;
			}
		}
	}
}
</script>