<template>
	<main>
		<div id="loader" v-if="isLoading"><div id="loader__wheel" ><p><span></span>LOADING...</p></div></div>

		<section class="mv">
			<h1>アクセス</h1>
		</section>
		<figure>
			<img @load="loaded" src="../../assets/ph_access.png" alt="">
		</figure>
		<address class="accessAddress">
			<p class="accessAddress__name"><img @load="loaded" src="../../assets/logo.svg" alt="医療法人慈圭会 すがのクリニック"></p>
			<div class="accessAddress__contact">
				<p>〒963-0209&nbsp;郡山市御前南６丁目16番地</p>
				<div class="accessAddress__contact__nums">
					<p><span>TEL.</span><a href="tel:0249663300">024-966-3300</a></p>
					<p><span>FAX.</span>024-966-3800</p>
				</div>
			</div>
		</address>

		<h3>交通案内</h3>
		<p>すがのクリニックはヨークベニマル・コスモス通り店の西隣です。</p>

		<section class="accessTrafic columns_2">
			<div class="accessTrafic__bus">
				<h3>バスをご利用の場合</h3>
				<ul>
					<li>9番乗り場から市役所経由静団地行、静御前で下車徒歩5分。</li>
					<li>11番乗り場から池の台経由静団地行、静御前で下車徒歩5分。</li>
					<li>11番乗り場からコスモス循環（池の台経由）ヨークベニマルコスモス通り前で下車徒歩2分。</li>
				</ul>
			</div>
			<div class="accessTrafic__car">
				<h3>高速道をご利用の場合</h3>
				<ul>
					<li>郡山南I・Cより車で7分。</li>
				</ul>
				<figure><img @load="loaded" src="../../assets/access_map.svg" alt=""></figure>
			</div>
		</section>
	</main>
</template>

<script>
import Meta from '../mixins/MetaSettable'

export default {
	mixins: [Meta],
	data() {
		return {
			isLoading: true,
			loadedCount: 0,
			cnt: 0,
			appClass: 'access'
		}
	},
	mounted() {
		// this.cnt = document.images.length;
		this.cnt = document.querySelectorAll('main img').length;
		if(this.cnt == 0){
			this.isLoading = false;
		}
		this.$emit('appClassMethod', this.appClass);
	},
	methods: {
		loaded () {
			this.loadedCount++;
			// console.log(this.loadedCount);;
			if(this.loadedCount === this.cnt) {
				this.isLoading = false;
			}
		}
	}
}
</script>