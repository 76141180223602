<template>
	<main>
		<div id="loader" v-if="isLoading"><div id="loader__wheel" ><p><span></span>LOADING...</p></div></div>

		<section class="mv">
			<h1>院内各課の紹介</h1>
		</section>

		<section class="hospitalMap">
			<h2>施設案内</h2>
			<figure class="hospitalMap__figure">
				<img @load="loaded" src="../../assets/hospital_map.svg" alt="">
				<a href="#" rel="4" class="hospitalMap__figure--playroom">プレイルーム</a>
				<a href="#" rel="3" class="hospitalMap__figure--examinationRoom">診察室</a>
				<a href="#" rel="5" class="hospitalMap__figure--dayCare">デイケア</a>
				<a href="#" rel="2" class="hospitalMap__figure--treatmentRoom">処置室</a>
				<a href="#" rel="1" class="hospitalMap__figure--reception">受付・事務</a>
				<a href="#" rel="0" class="hospitalMap__figure--waitingRoom">待合室</a>
			</figure>
		</section>

		<section class="facilities">
			<div class="facilities__card--waitingRoom">
				<h3>ロビー・待合室</h3>
				<figure><img @load="loaded" src="../../assets/ph_hospital_1.png" alt=""></figure>
			</div>
			<div class="facilities__card--reception">
				<h3>受付・会計</h3>
				<figure><img @load="loaded" src="../../assets/ph_hospital_2.png" alt=""></figure>
			</div>
			<div class="facilities__card--treatmentRoom">
				<h3>処置室</h3>
				<figure><img @load="loaded" src="../../assets/ph_hospital_3.png" alt=""></figure>
			</div>
			<div class="facilities__card--examinationRoom">
				<h3>診察室</h3>
				<figure><img @load="loaded" src="../../assets/ph_hospital_4.png" alt=""></figure>
			</div>
			<div class="facilities__card--playroom">
				<h3>プレイルーム</h3>
				<figure><img @load="loaded" src="../../assets/ph_hospital_5.png" alt=""></figure>
			</div>
			<div class="facilities__card--dayCare">
				<h3>デイケア</h3>
				<figure><img @load="loaded" src="../../assets/ph_hospital_6.png" alt=""></figure>
			</div>
		</section>

		<section class="eachSection">
			<h2>各課紹介</h2>
			<div class="eachSection__cards">
				<div class="eachSection__cards--nursing">
					<h3>看護課</h3>
					<div>
						<p>外来・病棟・デイケア・訪問看護と様々な業務を担当しています。どこにいても、一人ひとりの心を大切に、不安や心配を和らげられるよう目線を合わせた看護を心掛けています。</p>
					</div>
				</div>
				<div class="eachSection__cards--welfare">
					<h3>福祉課</h3>
					<div>
						<h4>精神保健福祉士</h4>
						<p>生活の中での心配や悩み、困りごとなどの相談をお受けします。福祉制度の紹介や、社会資源へとつなぐ支援をします。</p>
						<h4>作業療法士</h4>
						<p>楽しむ活動や人とのコミュニケーション、また生活管理などの作業を通して、自分らしい生活が送れるように支援します。</p>
					</div>
				</div>
				<div class="eachSection__cards--psychology">
					<h3>心理課</h3>
					<div>
						<p>臨床心理的技法を用いた様々なサポートを行っています。主な仕事には、個別相談（カウンセリング・親相談等）と、心理検査（知能・発達・性格傾向等）があります。</p>
					</div>
				</div>
				<!-- <div class="eachSection__cards--nutrition">
					<h3>栄養課</h3>
					<div>
						<p>手作りの家庭的な味、新しい味作りを心掛けています。利用者様とのコミュニケーションも大切に、食事が楽しみの一つになるよう励んでいます。</p>
					</div>
				</div> -->
				<div class="eachSection__cards--medical">
					<h3>医事課</h3>
					<div>
						<p>主に受付・会計業務を行っております。患者さんと接する機会が多いので、迅速かつ丁寧な対応を心掛けながら日々の業務に励んでおります。</p>
					</div>
				</div>
				<div class="eachSection__cards--grouphomeCaretaker">
					<h3>グループホーム世話人</h3>
					<div>
						<p>21名定員で4つのグループホームを管理・運営し、共同生活援助をしています。</p>
					</div>
				</div>
			</div>
		</section>
		<section class="overLay">
			<div class="hospitalGallery">
				<p class="hospitalGallery__closeBtn">close</p>
				<div class="hospitalGallery__mainArea">
					<h3 class="hospitalGallery__mainArea__title">ロビー・待合室</h3>
					<figure class="hospitalGallery__mainArea__photo"><img @load="loaded" src="/img/ph_hospital_01_zoom.png" alt=""></figure>
				</div>
				<div class="hospitalGallery__thumbs">
					<p class="hospitalGallery__thumbs__nav prev">←</p>
					<p class="hospitalGallery__thumbs__nav next">→</p>
					<div class="hospitalGallery__thumbs__wrap">
						<div class="hospitalGallery__thumbs__inner">
							<ul class="hospitalGallery__thumbs__list">
								<li>
									<p class="hospitalGallery__thumbs__photo"><img @load="loaded" src="/img/ph_hospital_01_thumb.png" alt=""></p>
									<h4>ロビー・待合室</h4>
								</li>
								<li>
									<p class="hospitalGallery__thumbs__photo"><img @load="loaded" src="/img/ph_hospital_02_thumb.png" alt=""></p>
									<h4>受付・事務</h4>
								</li>
								<li>
									<p class="hospitalGallery__thumbs__photo"><img @load="loaded" src="/img/ph_hospital_03_thumb.png" alt=""></p>
									<h4>処置室</h4>
								</li>
								<li>
									<p class="hospitalGallery__thumbs__photo"><img @load="loaded" src="/img/ph_hospital_04_thumb.png" alt=""></p>
									<h4>診察室</h4>
								</li>
								<li>
									<p class="hospitalGallery__thumbs__photo"><img @load="loaded" src="/img/ph_hospital_05_thumb.png" alt=""></p>
									<h4>プレイルーム</h4>
								</li>
								<li>
									<p class="hospitalGallery__thumbs__photo"><img @load="loaded" src="/img/ph_hospital_06_thumb.png" alt=""></p>
									<h4>デイケア</h4>
								</li>
								<!-- <li>
									<p class="hospitalGallery__thumbs__photo"><img @load="loaded" src="/img/ph_hospital_07_thumb.png" alt=""></p>
									<h4>施設写真07</h4>
								</li>
								<li>
									<p class="hospitalGallery__thumbs__photo"><img @load="loaded" src="/img/ph_hospital_08_thumb.png" alt=""></p>
									<h4>施設写真08</h4>
								</li>
								<li>
									<p class="hospitalGallery__thumbs__photo"><img @load="loaded" src="/img/ph_hospital_09_thumb.png" alt=""></p>
									<h4>施設写真09</h4>
								</li>
								<li>
									<p class="hospitalGallery__thumbs__photo"><img @load="loaded" src="/img/ph_hospital_10_thumb.png" alt=""></p>
									<h4>施設写真10</h4>
								</li>
								<li>
									<p class="hospitalGallery__thumbs__photo"><img @load="loaded" src="/img/ph_hospital_11_thumb.png" alt=""></p>
									<h4>施設写真11</h4>
								</li>
								<li>
									<p class="hospitalGallery__thumbs__photo"><img @load="loaded" src="/img/ph_hospital_12_thumb.png" alt=""></p>
									<h4>施設写真12</h4>
								</li>
								<li>
									<p class="hospitalGallery__thumbs__photo"><img @load="loaded" src="/img/ph_hospital_13_thumb.png" alt=""></p>
									<h4>施設写真13</h4>
								</li>
								<li>
									<p class="hospitalGallery__thumbs__photo"><img @load="loaded" src="/img/ph_hospital_14_thumb.png" alt=""></p>
									<h4>施設写真14</h4>
								</li>
								<li>
									<p class="hospitalGallery__thumbs__photo"><img @load="loaded" src="/img/ph_hospital_15_thumb.png" alt=""></p>
									<h4>施設写真15</h4>
								</li> -->
								<li>
									<p class="hospitalGallery__thumbs__photo"><img @load="loaded" src="/img/ph_hospital_16_thumb.png" alt=""></p>
									<h4>外観</h4>
								</li>
							</ul>
						</div>
					</div>
				</div>
			</div>
		</section>
	</main>
</template>

<script>
import Meta from '../mixins/MetaSettable'

export default {
	mixins: [Meta],
	data() {
		return {
			isLoading: true,
			loadedCount: 0,
			cnt: 0,
			appClass: 'hospital'
		}
	},
	mounted() {
		// this.cnt = document.images.length;
		this.cnt = document.querySelectorAll('main img').length;
		if(this.cnt == 0){
			this.isLoading = false;
		}
		this.$emit('appClassMethod', this.appClass);

		/* 院内マップギャラリー */
        let hospitalGalleryDOM = document.querySelector('.hospitalGallery');
        let mainAreaDOM = document.querySelector('.hospitalGallery__mainArea__photo');
        let mainImgDOM = document.querySelector('.hospitalGallery__mainArea__photo img');
        let thumbsAreaDOM = document.querySelector('.hospitalGallery__thumbs');
        let thumbsList = document.querySelector('.hospitalGallery__thumbs__list');
        let thmbsW = 0;
        let scrollW = 0
        let moveRange = 0;
        let moveUnit = 0;
        let posNum = 0;
        let thumbsArray = document.querySelectorAll('.hospitalGallery__thumbs__list li'); // サムネイル操作
        // console.log('thumbsNum: ' + thumbsArray.length);
        let galImgArr = document.querySelectorAll('.hospitalGallery__thumbs__list li img'); 
        let galTxtDOM = document.querySelectorAll('.hospitalGallery__thumbs__list li h4'); 
        // console.log(galTxtDOM);
        let galTxtArr = [];
        let mainTitleDOM = document.querySelector('.hospitalGallery__mainArea__title');
        // console.log(mainTitleDOM.innerText);
        let mainImg = document.querySelector('.hospitalGallery__mainArea__photo img');
        // console.log(mainImg);
        // mainImg.src = '../assets/ph_hospital_06_zoom.png';
        for(let i=0; i<galTxtDOM.length; i++){
            galTxtArr.push(galTxtDOM[i].innerText);
        }
        let prev = document.querySelector('.hospitalGallery__thumbs__nav.prev');
        let next = document.querySelector('.hospitalGallery__thumbs__nav.next');
        // next.style.opacity = 1;
        // console.log({galTxtArr});
        // if(hospitalGalleryDOM){
        //     // console.log('leftPos: ' + thumbsList.clientLeft + 'px');
        //     console.log({galImgArr});
        //     // console.log('galImgArr[0].baseURI: ' + galImgArr[0].baseURI);
        //     // console.log('galImgArr[0].currentSrc: ' + galImgArr[0].currentSrc);
        //     // console.log('galImgArr[0]のthisZoomSrc: ' + galImgArr[0].currentSrc.replace('_thumb', '_zoom'));
        // }
        function setHospitalGallery(posNum){ // 初期化
            if(posNum === null || posNum === undefined || posNum === ''){
                posNum = 0;
            }
            if(hospitalGalleryDOM){
                hospitalGalleryDOM.style.height = (mainImgDOM.clientHeight + thumbsAreaDOM.clientHeight + 100)+'px';
                moveUnit = document.querySelectorAll('.hospitalGallery__thumbs__list li')[0].clientWidth;
                // console.log({moveUnit});
                thmbsW = thumbsList.clientWidth;
                // console.log({thmbsW});
                scrollW = document.querySelector('.hospitalGallery__thumbs__wrap').clientWidth;
                // console.log({scrollW});
                moveRange = thmbsW - scrollW;
                // console.log({moveRange});
            }
            moveGalleryThumbs(posNum);
            changeMainImg(posNum);

            // console.log(mainImgDOM.clientHeight);
            mainAreaDOM.style.height = mainImgDOM.clientHeight + 'px';
        }
        setHospitalGallery(posNum);
        // mainImgDOM.classList.add('view');
        // setTimeout(() => {
        // 	setHospitalGallery(7);
        // }, 2000);
        
        for(let i = 0; i < thumbsArray.length; i++){
            (function(k){
                thumbsArray[k].addEventListener('click', function(){
                    posNum = k;
                    // console.log({posNum});
                    changeMainImg(posNum);
                    moveGalleryThumbs(posNum);
                    for(let j = 0; j < thumbsArray.length; j++){
                        thumbsArray[j].classList.remove('current');
                    }
                    thumbsArray[k].classList.add('current');
                });
            })(i);
        }

        if(hospitalGalleryDOM){ // 左右ナビボタン操作
            // console.log(posNum);
            // console.log( Math.ceil(scrollW / moveUnit / 2) );
            let posMin = Math.ceil(scrollW / moveUnit / 2);
            prev.addEventListener('click', function(){
                posNum--;
                if(posNum < 0){ posNum = 0; }
                if(posNum > galImgArr.length-posMin-1 ){ posNum = galImgArr.length-posMin-1; }
                moveGalleryThumbs(posNum);
                // console.log(posNum);
            });
            next.addEventListener('click', function(){
                posNum++;
                if(posNum > galImgArr.length){ posNum = galImgArr.length; }
                if(posNum < posMin ){ posNum = posMin; }
                moveGalleryThumbs(posNum);
                // console.log(posNum);
            });
        }

        function changeMainImg(posNum){ // メイン画像入れ替え関数
            if(hospitalGalleryDOM){
                let thisSrc = galImgArr[posNum].src;
                // console.log(thisSrc);
                let thisZoomSrc = thisSrc.replace("thumb", "zoom");
                // let thisZoomSrc = thisSrc;
                // console.log(thisZoomSrc);
                let thisTxt = galTxtArr[posNum];
                // console.log({thisTxt});
                mainImg.src = thisZoomSrc;
                mainImg.alt = thisTxt;
                // let img = document.createElement('img');
                // img.src = thisZoomSrc;
                // img.alt = thisTxt;
                mainTitleDOM.innerText = thisTxt;
                // mainAreaDOM.appendChild(img);
                // img.classList.add('view');
                // mainImgDOM = document.querySelector('.hospitalGallery__mainArea__photo img');

                // thumbsArray[posNum].classList.add('current');
                for(let j = 0; j < thumbsArray.length; j++){
                    thumbsArray[j].classList.remove('current');
                }
                thumbsArray[posNum].classList.add('current');
            }
        }
        changeMainImg(posNum);
        function moveGalleryThumbs(posNum){ // サムネイルスクロール関数
            if(hospitalGalleryDOM){
                if(thmbsW <= scrollW){
                    thumbsList.style.left = '0px';
                    prev.classList.remove('active');
                    next.classList.remove('active');
                }else{
                    let leftPos = -(posNum*moveUnit)+(scrollW/2)-45;
                    // console.log('changeLeftPos: '+ leftPos +'px');
                    prev.classList.add('active');
                    next.classList.add('active');
                    if(leftPos > 1){
                        leftPos = 0;
                        prev.classList.remove('active');
                    }
                    if(leftPos < -moveRange){
                        leftPos = -moveRange;
                        next.classList.remove('active');
                    }
                    thumbsList.style.left = leftPos+'px';
                }
            }
        }
        
        // 仮数 hospitalGalleryDOMの高さ（済）
        //      __thumbsの高さ

        // オーバーレイ
        let overlay = document.querySelector(".overLay");
        let closeBtn = document.querySelector(".hospitalGallery__closeBtn");
        if(closeBtn){
            closeBtn.addEventListener('click', function(){
                // fadeOut(overlay, 300);
                overlay.classList.remove('show');
            });
        }
        
        // 院内マップクリック動作
        let hospitalMapTrigger = document.querySelectorAll(".hospitalMap__figure a");
        // console.log({hospitalMapTrigger});
        for(let i =0; i < hospitalMapTrigger.length; i++){
            (function(k){
                hospitalMapTrigger[k].addEventListener('click', function(e){
                    e.preventDefault();
                    // console.log(hospitalMapTrigger[k].rel);
                    overlay.classList.add('show');
                    // fadeIn(overlay, 300);
                    setHospitalGallery(hospitalMapTrigger[k].rel);
                });
            })(i);
        }

        // リサイズ時
        var hospitalTimer = false;
        window.addEventListener('resize',function() {
            if (hospitalTimer !== false) {
                clearTimeout(hospitalTimer);
            }
            hospitalTimer = setTimeout(function() {
                // 一度だけ
                setHospitalGallery();
            }, 200);
        });
	},
	methods: {
		loaded () {
			this.loadedCount++;
			// console.log(this.loadedCount);;
			if(this.loadedCount === this.cnt) {
				this.isLoading = false;
			}
		}
	}
}
</script>