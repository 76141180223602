<template>
	<main>
		<div id="loader" v-if="isLoading"><div id="loader__wheel" ><p><span></span>LOADING...</p></div></div>

		<section class="mv">
			<h1>外来のご案内</h1>
		</section>
		
		<section class="outpatient__intro columns_2">
			<div>
				<p>うつ病や不安障害、統合失調症などの精神疾患、多動性障害や自閉スペクトラム症などのいわゆる発達障害の方の診察や治療を行っています。</p>
				<table class="outpatient__tot">
					<caption>月〜土（日・祝祭日は休診）</caption>
					<thead>
						<tr>
							<th>診療時間</th>
							<th>月</th>
							<th>火</th>
							<th>水</th>
							<th>木</th>
							<th>金</th>
							<th>土</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>9：00～12：00</td>
							<td>●</td>
							<td>●</td>
							<td></td>
							<td>●</td>
							<td>●</td>
							<td>●</td>
						</tr>
						<tr>
							<td>2：00～5：00</td>
							<td>●</td>
							<td></td>
							<td></td>
							<td>●</td>
							<td></td>
							<td>●</td>
						</tr>
						<tr>
							<td>6：00～8：00</td>
							<td></td>
							<td>●</td>
							<td></td>
							<td></td>
							<td>●</td>
							<td></td>
						</tr>
					</tbody>
				</table>
			</div>
			<figure><img @load="loaded" src="../../assets/ph_outpatient_01.png" alt=""></figure>
		</section>

		<h2>外来診療</h2>
		<section class="outpatient__guidance columns_2">
			<div>
				<figure><img @load="loaded" src="../../assets/ph_outpatient_02.png" alt=""></figure>
				<p>はじめはこれまでの経過や現在の症状などを聞きながら困りごとなどを整理していき、さらに必要に応じて各種検査を行います。診断や症状に合わせて治療をすすめ、必要があると考えられれた場合には薬による治療も行います。</p>
				<ul>
					<li>必ず電話でご予約されてからお越し下さい。</li>
					<li>当クリニックでは、ご予約の患者さんを優先に診察しておりますので、ご了承下さい。</li>
				</ul>
				<p class="outpatient__guidance__tel"><span>TEL .</span>024-966-3300</p>
			</div>
			<div class="outpatientGuidance__timeTable">
				<table class="outpatient__tot--miho">
					<caption>鈴木実穂（第１診療室）</caption>
					<thead>
						<tr>
							<th>診療時間</th>
							<th>月</th>
							<th>火</th>
							<th>水</th>
							<th>木</th>
							<th>金</th>
							<th>土</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>9：00～12：00</td>
							<td>●</td>
							<td>●</td>
							<td></td>
							<td>●</td>
							<td>●</td>
							<td>●</td>
						</tr>
						<tr>
							<td>2：00～5：00</td>
							<td>●</td>
							<td></td>
							<td></td>
							<td>●</td>
							<td></td>
							<td>●</td>
						</tr>
						<tr>
							<td>6：00～8：00</td>
							<td></td>
							<td>●</td>
							<td></td>
							<td></td>
							<td>●</td>
							<td></td>
						</tr>
					</tbody>
				</table>
				<table class="outpatient__tot--akiichi">
					<caption>鈴木章市（第２診療室）</caption>
					<thead>
						<tr>
							<th>診療時間</th>
							<th>月</th>
							<th>火</th>
							<th>水</th>
							<th>木</th>
							<th>金</th>
							<th>土</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>9：00～12：00</td>
							<td>●</td>
							<td>●</td>
							<td></td>
							<td>●</td>
							<td></td>
							<td>●</td>
						</tr>
						<tr>
							<td>2：00～5：00</td>
							<td>●</td>
							<td></td>
							<td></td>
							<td>●</td>
							<td></td>
							<td>●</td>
						</tr>
						<tr>
							<td>6：00～8：00</td>
							<td></td>
							<td>●</td>
							<td></td>
							<td></td>
							<td>●</td>
							<td></td>
						</tr>
					</tbody>
				</table>
				<table class="outpatient__tot--shirokizawa">
					<caption>白木澤史子（第３診療室）</caption>
					<thead>
						<tr>
							<th>診療時間</th>
							<th>月</th>
							<th>火</th>
							<th>水</th>
							<th>木</th>
							<th>金</th>
							<th>土</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>9：00～12：00</td>
							<td>●</td>
							<td>●</td>
							<td></td>
							<td>●</td>
							<td></td>
							<td>●</td>
						</tr>
						<tr>
							<td>2：00～5：00</td>
							<td>●</td>
							<td></td>
							<td></td>
							<td>●</td>
							<td></td>
							<td>●</td>
						</tr>
						<tr>
							<td>6：00～8：00</td>
							<td></td>
							<td>●</td>
							<td></td>
							<td></td>
							<td></td>
							<td></td>
						</tr>
					</tbody>
				</table>
			</div>
		</section>
		
	</main>
</template>

<script>
import Meta from '../mixins/MetaSettable'

export default {
	mixins: [Meta],
	data() {
		return {
			isLoading: true,
			loadedCount: 0,
			cnt: 0,
			appClass: 'outpatient'
		}
	},
	mounted() {
		// this.cnt = document.images.length;
		this.cnt = document.querySelectorAll('main img').length;
		if(this.cnt == 0){
			this.isLoading = false;
		}
		this.$emit('appClassMethod', this.appClass);
	},
	methods: {
		loaded () {
			this.loadedCount++;
			// console.log(this.loadedCount);;
			if(this.loadedCount === this.cnt) {
				this.isLoading = false;
			}
		}
	}
}
</script>